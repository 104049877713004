import { ajax } from 'jquery';
import {orderBy} from 'lodash';

let baseUrl = '';

if (window.location.href.indexOf('localhost') !== -1) {
  baseUrl = 'https://app.wellport.us';
}

export const getImages = async (token) => {
  const images = await ajax({
    url: `${baseUrl}/api/user/images?token=${token}`,
    type: "GET",
    dataType: 'json',
    xhrFields: {
       withCredentials: true
    }
  });

  return orderBy(images, ['timestamp'], ['desc']);
}

export const addImage = (token, data) => ajax({
  url: `${baseUrl}/api/user/images?token=${token}`,
  type: "POST",
  dataType: 'json',
  data: data,
  xhrFields: {
     withCredentials: true
  }
});
