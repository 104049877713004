import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CreateOrg from 'components/forms/CreateOrg';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 500,
    margin: '0 auto',
    marginTop: 100,
    padding: theme.spacing(3, 2),
  },
}));

export default function Home() {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <CreateOrg />
    </Paper>
  );
}
