import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';

const firebaseConfig = require('constants/firebase');

firebase.initializeApp(firebaseConfig);
firebase.analytics();

function b64toBlob(b64, onsuccess, onerror) {
  var img = new Image();
  img.onerror = onerror;
  img.onload = function onload() {
      var canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(onsuccess);
  };
  img.src = b64;
}

export const uploadImage = (base64) => {
  return new Promise((resolve, reject) => {
    b64toBlob(base64, (blob) => {
      const id = uuidv4();
      console.log(blob);
      firebase.storage().ref()
      .child(`images/${id}`)
      .put(blob)
      .then(data => {
        console.log(data);
        resolve(`images/${id}`);
      })
      .catch(err => {
        console.log(err);
        resolve('');
      })
    }, () => resolve(''));
  });
}

export const getImageUrl = (path) => firebase.storage().ref(path).getDownloadURL();
