import React, {useEffect, useState, memo} from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Progress from 'components/shared/Progress';
import {getImageUrl} from 'utils/upload';
import moment from 'moment';

function Upload({upload, user}) {

  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (upload.qrcode) {
      setUrl(`data:image/png;base64, ${upload.qrcode}`);
      return;
    }
    getImageUrl(upload.image)
    .then((result) => setUrl(result))
    .catch((err) => console.log(err))
  }, [upload.image, upload.qrcode]);

  if (!url) {
    return <Progress />;
  }

  const getStatus = () => {
    if (!upload || !upload.result) {
      return 'PROCESSING';
    }

    if (upload.result.status === 'PROCESSED') {
      if (upload.result.isSick) {
        if (upload.result.temperature){
          return `Sick (${parseFloat(upload.result.temperature, 10).toFixed(2)})`;
        } else {
          return 'Sick';
        }
      } else {
        if (upload.result.temperature){
          return `Healthy (${parseFloat(upload.result.temperature, 10).toFixed(2)})`;
        } else {
          return 'Healthy';
        }
      }
    }

    if (upload.result.status === 'FAILED') {
      return 'TRY AGAIN';
    }
    return upload.result.status;
  }

  return (
    <Card>
      <CardActionArea>
        <CardMedia
          component="img"
          alt="Uploaded image"
          height="300"
          image={url}
          title="Uploaded image"
          style={{objectFit: 'initial'}}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {getStatus()}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {
              !!user
              ? `Uploaded by ${user.email} at ${moment(upload.timestamp).format('LLLL')}`
              : `Uploaded at ${moment(upload.timestamp).format('LLLL')}`
            }
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
function arePropsEqual(prevProps, nextProps) {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
}
export default memo(Upload, arePropsEqual);
