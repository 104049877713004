import {map} from 'lodash';

export const isEmailValid = email => {
	var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}


export const stringToArray = (string, split) => {
  if (!string) {
    return [];
  }

  return map(string.split(split), item => {
    return item.trim();
  });
}
