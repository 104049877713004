import React from 'react';
import Grid from '@material-ui/core/Grid';
import Upload from 'components/shared/Upload';
import Progress from 'components/shared/Progress';
import {map} from 'lodash';

export default function Images({images}) {
  if (!images) {
    return <Progress />;
  }

  return (
    <Grid container spacing={5}>
      {
        map(images, image => (
          <Grid key={image.id} item xs={12} sm={6} md={6} lg={4}>
            <Upload upload={image} />
          </Grid>
        ))
      }
    </Grid>
  );
}
