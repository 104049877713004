import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from 'views/Home'
import Admin from  'views/Admin';
import User from  'views/User';
import VerifyScan from  'views/VerifyScan';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import logo from 'images/logo.png';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1200,
    width: '100%',
    margin: '0 auto',
  },
  header: {
    padding: 20,
    backgroundColor: 'white',
  },
  logo: {
    height: 40,
  },
}));

function App() {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.header}>
        <div className={classes.container}>
          <a href="/">
            <img src={logo} alt="logo" className={classes.logo}/>
          </a>
        </div>
      </div>
      <div className={classes.container}>
        <Router>
          <Switch>
            <Route path="/organization/:token" component={Admin} />
            <Route path="/user/:token" component={User} />
            <Route path="/verify_scan" component={VerifyScan} />
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </div>
    </MuiPickersUtilsProvider>
  );
}

export default App;
