import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import jwtDecode from 'jwt-decode';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 500,
    margin: '0 auto',
    marginTop: 100,
    padding: theme.spacing(3, 2),
  },
}));

const getPayload = () => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const payload = urlParams.get('payload');
    return jwtDecode(payload);
  } catch (err) {
    console.log(err);
    return null;
  }
}
export default function Home({match}) {
  const classes = useStyles();

  const payload  = getPayload();
  console.log(payload);
  if (!payload || !payload.user_id) {
    return (
      <Paper className={classes.root}>
        <Typography variant="h3" color="error" align="center">
          Invalid Payload!
        </Typography>
      </Paper>
    )
  }

  if (payload.is_sick) {
    return (
      <Paper className={classes.root}>
        <Typography variant="h3" color="error" align="center">
          Sick!<br />
        </Typography>
        <Typography variant="body1" align="center">
          <br /><br />
          User ID: {payload.user_id}<br /><br />
          Verified At: {moment(parseInt(payload.timestamp*1000, 10)).format('LLLL')}<br /><br />
          {payload.approved_by}
        </Typography>
      </Paper>
    )
  }
  return (
    <Paper className={classes.root}>
      <Typography variant="h3" color="success" align="center">
        Healthy!<br />
      </Typography>
      <Typography variant="body1" align="center">
        <br /><br />
        User ID: {payload.user_id}<br /><br />
        Verified At: {moment(parseInt(payload.timestamp*1000, 10)).format('LLLL')}<br /><br />
        {payload.approved_by}
      </Typography>
    </Paper>
  );
}
