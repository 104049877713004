import { ajax } from 'jquery';

let baseUrl = '';

if (window.location.href.indexOf('localhost') !== -1) {
  baseUrl = 'https://app.wellport.us';
}

export const createOrg = (data) => ajax({
  url: `${baseUrl}/api/open/create-org`,
  type: "POST",
  dataType: 'json',
  data: data,
  xhrFields: {
     withCredentials: true
  }
});
