import React, {useState, useEffect, memo, Fragment} from 'react';
import Paper from '@material-ui/core/Paper';
import {forEach, values, orderBy} from 'lodash';
import {getCheckins} from 'api/admin';
import moment from 'moment';
import Progress from 'components/shared/Progress';
import BarChart from 'components/shared/BarChart';

function Data({token, date}) {
  const [checkins, setCheckins] = useState(null);

  useEffect(() => {
    setCheckins(null)
    getCheckins(
      token,
      moment(date).startOf('day').valueOf(),
      '',
    ).then(setCheckins);
  }, [token, date]);

  if (!checkins) {
    return <Progress />;
  }

  if (!checkins.length) {
    return null;
  }

  const days = {};
  forEach(checkins, checkin => {
    const date = moment(checkin.timestamp).startOf('day').format('LL');
    if (!days[date]) {
      days[date] = {
        timestamp: checkin.timestamp,
        name: date,
        FAILED: 0,
        PROCESSING: 0,
        PROCESSED: 0,
        SICK: 0,
        HEALTHY: 0,
      }
    }
    if (typeof days[date][checkin.status] !== 'undefined') {
      days[date][checkin.status]++;
    }
    if (typeof checkin.isSick !== 'undefined') {
      if (checkin.isSick) {
        days[date].SICK++;
      } else {
        days[date].HEALTHY++;
      }
    }
  })

  const keys = [
    {
      name: 'FAILED',
      stackId: 'a',
    },
    {
      name: 'PROCESSING',
      stackId: 'a',
    },
    {
      name: 'PROCESSED',
      stackId: 'a',
    },
    {
      name: 'SICK',
      stackId: 'b',
    },
    {
      name: 'HEALTHY',
      stackId: 'b',
    },
  ]

  return (
    <Fragment>
      <Paper>
        <BarChart keys={keys} data={orderBy(values(days), 'timestamp')} />
      </Paper>
      <br />
    </Fragment>
  );
}

export default memo(Data);
