import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {isEmailValid, stringToArray} from 'utils/string';
import {forEach} from 'lodash';
import {addUsers} from 'api/admin';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 30,
  },
  input: {
    marginTop: 20,
    marginBottom: 20,
  },
}));


function CreateOrg({token, onAddUsers}) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const onChange = key => (event) => {
    setData({
      ...data,
      [key]: event.target.value,
    });
  }

  const classes = useStyles();

  const canSubmit = () => {
    const emails = stringToArray(data.emails, '\n');
    if (!emails.length) {
      return false;
    }
    let valid = true;
    forEach(emails, email => {
      console.log(email, isEmailValid(email))
      if (!isEmailValid(email)) {
        valid = false;
      }
    });
    return valid;
  }

  const onSubmit = () => {
    setLoading(true);
    addUsers(token, {
      emails: stringToArray(data.emails, '\n'),
    })
    .then(() => {
      onAddUsers();
      setLoading(false);
      setData('');
    });
  }

  return (
    <div className={classes.root}>
      <TextField
        multiline
        rows={5}
        className={classes.emails}
        label="Emails"
        fullWidth
        onChange={onChange('emails')}
        value={data.emails || ''}
        helperText={'Enter an email address to invite users. Adding multiple at once? Simply separate them with new line'}
      />
      <Button
        className={classes.input}
        fullWidth
        variant="contained"
        color="primary"
        disabled={loading || !canSubmit()}
        onClick={onSubmit}
      >
        Add Users
      </Button>
    </div>
  );
}

export default CreateOrg;
