import React, {useState, useEffect, memo} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {map, forEach} from 'lodash';
import {getUploads} from 'api/admin';
import moment from 'moment';
import Progress from 'components/shared/Progress';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    maxHeight: 300,
    overflow: 'auto',
  },
  notice: {
    margin: '100px 30px',
  },
  img: {
    objectFit: 'initial',
  }
}));

function Data({users, token, date}) {
  const classes = useStyles();
  const [uploads, setUploads] = useState(null);

  useEffect(() => {
    setUploads(null)
    getUploads(
      token,
      moment(date).startOf('day').valueOf(),
      moment(date).endOf('day').valueOf(),
      '',
    ).then(setUploads);
  }, [token, date]);

  if (!uploads || !users) {
    return <Progress />;
  }

  if (!users.length) {
    return (
      <Typography variant="h6" align="center" className={classes.notice}>
        You do not have any users in your organiation yet.
      </Typography>
    )
  }

  if (!uploads.length) {
    return (
      <Typography variant="h6" align="center" className={classes.notice}>
        No images uploaded for the selected date
      </Typography>
    )
  }

  const usersObj = {};
  forEach(users, user => {
    usersObj[user.id] = user;
  })

  const getStatus = upload => {
    if (!upload || !upload.result) {
      return  '';
    }
    if (upload.result.status === 'PROCESSED') {
      return upload.result.isSick ? ' - SICK' : ' - HEALTHY';
    }
    return '';
  }

  return (
    <Grid container spacing={5}>
      {
        map(uploads, upload => (
          <Grid item key={upload.id} xs={12} md={6}>
          <Card>
            <CardActionArea>
              {
                upload.qrcode ? (
                  <CardMedia
                    component="img"
                    alt="Uploaded image"
                    height="300"
                    image={`data:image/png;base64, ${upload.qrcode}`}
                    title="Uploaded image"
                    className={classes.img}
                  />
                ) : null
              }
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {upload.result.status} {getStatus(upload)}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {
                    !!usersObj[upload.userId]
                    ? `Uploaded by ${usersObj[upload.userId].email} at ${moment(upload.timestamp).format('LLLL')}`
                    : `Uploaded at ${moment(upload.timestamp).format('LLLL')}`
                  }
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          </Grid>
        ))
      }
    </Grid>
  );
}

export default memo(Data);
