import React from 'react';
import {map} from 'lodash';
import {ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';

const colors = [
  '#00896F',
  '#936C00',
  '#845EC2',
  '#4B4453',
  '#B0A8B9',
  '#00C0A3',
  '#008AC4',
  '##C34A36'
]

export default function Chart({data, keys}) {
  return (
    <ResponsiveContainer width="100%" height={320}>
      <BarChart data={data}
            margin={{top: 20, right: 30, left: 20, bottom: 5}}>
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis dataKey="name"/>
        <YAxis/>
        <Tooltip/>
        <Legend />
        {
          map(keys, (key, index) => <Bar key={key.key} dataKey={key.name} stackId={key.stackId} fill={colors[index % colors.length]} />)
        }
      </BarChart>
    </ResponsiveContainer>
  );
}
