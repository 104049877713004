import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(() => ({
  root: {
    margin: 30,
  }
}))

export default function Progress(props) {
  const classes = useStyles();

  return (
    <LinearProgress className={classes.root} />
  );
}
