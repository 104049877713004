import { ajax } from 'jquery';
import {orderBy} from 'lodash';

let baseUrl = '';

if (window.location.href.indexOf('localhost') !== -1) {
  baseUrl = 'https://app.wellport.us';
}

export const getUsers = (token) => ajax({
  url: `${baseUrl}/api/admin/users?token=${token}`,
  type: "GET",
  dataType: 'json',
  xhrFields: {
     withCredentials: true
  }
});

export const addUsers = (token, data) => ajax({
  url: `${baseUrl}/api/admin/users?token=${token}`,
  type: "POST",
  dataType: 'json',
  data: data,
  xhrFields: {
     withCredentials: true
  }
});

export const getUploads = async (token, from, to, userId) => {
  const uploads = await ajax({
    url: `${baseUrl}/api/admin/uploads?token=${token}&to=${to}&from=${from}&userId=${userId}`,
    type: "GET",
    dataType: 'json',
    xhrFields: {
       withCredentials: true
    }
  });
  return orderBy(uploads, ['timestamp'], ['desc']);
}

export const getCheckins = async (token, around, userId) => {
  const checkins = await ajax({
    url: `${baseUrl}/api/admin/checkins?token=${token}&around=${around}&userId=${userId}`,
    type: "GET",
    dataType: 'json',
    xhrFields: {
       withCredentials: true
    }
  });
  return orderBy(checkins, ['timestamp'], ['desc']);
}
