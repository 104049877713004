import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ImageUpload from 'components/user/ImageUpload';
import Images from 'components/user/Images';
import {getImages} from 'api/user';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 700,
    margin: '0 auto',
    marginTop: 40,
    flexGrow: 1,
    padding: theme.spacing.unit,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    minHeight: '100%',
    overflow: 'auto',
  },
  notice: {
    margin: '100px 30px',
  }
}));

export default function User({match}) {
  const token = match.params.token
  const classes = useStyles();
  const [images, setImages] = useState(null);

  useEffect(() => {
    const timer = setInterval(() => {
      getImages(token).then((images) => setImages(images));
    }, 1000 * 60);
    return () => clearInterval(timer);
  }, [token]);

  useEffect(() => {
    getImages(token).then((images) => setImages(images));
  }, [token]);

  const fetchImages = () => {
    getImages(token).then((images) => setImages(images));
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={5} direction="row" justify="space-evenly" alignItems="stretch">
        <Grid item xs={12} sm={2} md={3} />
        <Grid item xs={12} sm={8} md={6} >
          <Paper className={classes.paper}>
            <ImageUpload token={token} onUpload={fetchImages} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={2} md={3} />
        <Grid item xs={12}>
          <Images images={images} />
        </Grid>
      </Grid>
    </div>
  );
}
