module.exports = {
  apiKey: "AIzaSyD7-2mNii030wbXbCRYRpMnfGt8lWxz1qA",
  authDomain: "positivity-171701.firebaseapp.com",
  databaseURL: "https://positivity-171701.firebaseio.com",
  projectId: "positivity-171701",
  storageBucket: "positivity-171701.appspot.com",
  messagingSenderId: "240848550285",
  appId: "1:240848550285:web:80721ea8be8ef5be0b65c4",
  measurementId: "G-KVKP98L6X8"
};
