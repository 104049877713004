import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import Progress from 'components/shared/Progress';
import {map} from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    maxHeight: 300,
    overflow: 'auto',
  },
  notice: {
    margin: '100px 30px',
  }
}));

export default function Users({users}) {
  const classes = useStyles();
  if (!users) {
    return <Progress />;
  }
  if (!users.length) {
    return (
      <Typography variant="h6" align="center" className={classes.notice}>
        You do not have any users in your organiation yet. Please use the form below to invite users
      </Typography>
    )
  }

  return (
    <List className={classes.root}>
      {
        map(users, user => (
          <ListItem key={user.id}>
            <ListItemAvatar>
              <Avatar>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={user.email} secondary={user.id} />
          </ListItem>
        ))
      }
    </List>
  );
}
