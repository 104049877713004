import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Camera, {FACING_MODES} from 'react-html5-camera-photo';
import Webcam from "react-webcam";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Progress from 'components/shared/Progress';
import Fab from '@material-ui/core/Fab';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import {uploadImage} from 'utils/upload';
import {addImage} from 'api/user';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  overlayTop: {
    position: 'absolute',
    backgroundColor: '#000',
    top: 0,
    left: 0,
    right: 0,
    height: '30%',
    opacity: 0.4,
  },
  overlayRight: {
    position: 'absolute',
    backgroundColor: '#000',
    top: '30%',
    bottom: '30%',
    right: 0,
    width: '10%',
    opacity: 0.4,
  },
  overlayBottom: {
    position: 'absolute',
    backgroundColor: '#000',
    bottom: 0,
    left: 0,
    right: 0,
    height: '30%',
    opacity: 0.4,
  },
  overlayLeft: {
    position: 'absolute',
    backgroundColor: '#000',
    top: '30%',
    bottom: '30%',
    left: 0,
    width: '10%',
    opacity: 0.4,
  },
  border: {
    position: 'absolute',
    top: '30%',
    bottom: '30%',
    left: '10%',
    right: '10%',
    border: 'solid 1px green',
  },
  instructions: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    textAlign: 'center',
    fontSize: 18,
    transform: 'translate(-50%, -50%)',
    width: '90%',
    color: 'white',
  },
  image: {
    width: '100%',
  },
  dialog: {
    width: '100%',
  },
  thanks: {
    padding: 50,
  },
  button: {
    position: 'absolute',
    bottom: '10%',
    left: '50%',
    transform: 'translate(-50%, 0%)',
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImageUpload({token, onUpload}) {
  const classes = useStyles();

  const [image, setImage] = useState(null);
  const [uploaded, setUploaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setImage(null);
  };

  function handleTakePhoto (image) {
    setImage(image);
    setLoading(false);
    // Do stuff with the photo...
    console.log('takePhoto');
  }

  const handleUpload = () => {
    if (!image) {
      return;
    }
    setLoading(true);
    uploadImage(image)
    .then((path) => addImage(token, {image: path}))
    .then(() => {
      setImage(null);
      setLoading(false);
      setUploaded(true);
      onUpload();
    });
  }

  let content = null;
  if (uploaded) {
    content = (
      <div className={classes.thanks}>
        <Typography align="center">
          Picture has been uploaded successully and is being processed.
          <br /><br /><br />
          <Button variant="contained" color="primary" onClick={() => setUploaded(false)}>
            Upload another picture
          </Button>
        </Typography>
      </div>
    )
  } else {
    content = (
      <div className={classes.root}>
        <Webcam videoConstraints={{facingMode: 'environment'}} ref={camera => window.camera = camera} />
        <div className={classes.overlayTop}>
          <p className={classes.instructions}>
            Take a picture of the thermometer by focusing the number in the box.
          </p>
        </div>
        <div className={classes.overlayRight} />
        <div className={classes.overlayBottom} />
        <div className={classes.overlayLeft} />
        <div className={classes.border} />
        <Fab color="primary"  className={classes.button} onClick={() => setImage(window.camera.getScreenshot())}>
          <PhotoCamera />
        </Fab>
      </div>
    );
  }

  return (
    <div>
      {content}
      <Dialog
        open={!!image}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
      >
        <DialogTitle id="form-dialog-title">Upload Photo</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <img alt="camera" src={image} className={classes.image} />
          </DialogContentText>
        </DialogContent>
        {
          loading ? <Progress /> : null
        }
        <DialogActions>
          <Button disabled={loading} onClick={handleClose} color="primary">
            Retake
          </Button>
          <Button disabled={loading} onClick={handleUpload} color="primary" variant="contained">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
