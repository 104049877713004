import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import AddUsers from 'components/forms/AddUsers';
import Users from 'components/admin/Users';
import Data from 'components/admin/Data';
import Checkins from 'components/admin/Checkins';
import {getUsers} from 'api/admin';
import {Calendar} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  root: {
      marginTop: 20,
    flexGrow: 1,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit,
  },
  calender: {
    maxWidth: 300,
    margin: '0 auto',
  },
  divider: {
    margin: 30,
  }
}));

export default function Admin({match}) {
  const classes = useStyles();
  const [users, setUsers] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const token = match.params.token

  useEffect(() => {
    getUsers(token).then(setUsers);
  }, [token]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const onAddUsers = () => {
    getUsers(token).then(setUsers);
  }

  return (
    <div className={classes.root}>
      <Grid spacing={2} container>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <div className={classes.calender}>
              <Calendar date={selectedDate} onChange={handleDateChange} />
            </div>
          </Paper>
          <br />
          <Hidden xsDown>
            <Paper className={classes.paper}>
              <Users token={token} users={users} />
              <Divider className={classes.divider} />
              <AddUsers token={token} onAddUsers={onAddUsers} />
            </Paper>
          </Hidden>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <Checkins token={token} users={users} date={selectedDate} />
          <Data token={token} users={users} date={selectedDate} />
        </Grid>
      </Grid>
    </div>
  );
}
