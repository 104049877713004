import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {createOrg} from 'api/open';
import {isEmailValid} from 'utils/string';
import Progress from 'components/shared/Progress';

const useStyles = makeStyles(theme => ({
  input: {
    marginTop: 20,
    marginBottom: 20,
  },
}));


function CreateOrg() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const onChange = key => (event) => {
    setData({
      ...data,
      [key]: event.target.value,
    });
  }

  const classes = useStyles();

  const canSubmit = () => data.email && data.name && isEmailValid(data.email);

  const onSubmit = () => {
    setLoading(true);
    createOrg(data)
    .then(() => setSubmitted(true));
  }

  const onReset = () => {
    setData({});
    setLoading(false);
    setSubmitted(false);
  }

  if (submitted) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.input} align="center" variant="h5">
              Thanks for signing up! Check your inbox for login instructions.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.input}
            fullWidth
            variant="contained"
            color="primary"
            onClick={onReset}
          >
            Back
          </Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.input} align="center" variant="h4">
            Lets get started!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.input}
          label="Organization Name"
          fullWidth
          onChange={onChange('name')}
          value={data.name || ''}
        />
      </Grid>
        <Grid item xs={12}>
          <TextField
            type="email"
            className={classes.input}
            label="Organization Email"
            fullWidth
            onChange={onChange('email')}
            value={data.email || ''}
            helperText={'This email will be given access to the uploaded data and dashboard view'}
          />
        </Grid>
      <Grid item xs={12}>
        {
          loading ? <Progress /> : null
        }
        <Button
          className={classes.input}
          fullWidth
          variant="contained"
          color="primary"
          disabled={loading || !canSubmit()}
          onClick={onSubmit}
        >
          Create your WellPort
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.input} align="center" variant="caption">
            We’ll send you an email with a link to your WellPort account.
            Add it to your bookmarks to find it quickly in the future.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default CreateOrg;
